var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'spendToDate' && _vm.item.budgeting.spendToDate.value)?_c('div',[_vm._v(" $"+_vm._s(_vm.item.budgeting.spendToDate.value)+" ")]):(_vm.type === 'projectedSpend' && _vm.item.budgeting.projectedSpend.value)?_c('div',[_c('projected-spend-tooltip',{attrs:{"value":_vm.item.budgeting,"timing":_vm.timing}})],1):(_vm.type === 'dailyBudget' && _vm.item.budgeting.dailyBudget.value)?_c('div',{staticClass:"budget-cell",class:{
            'budget-warning': !_vm.item.budgeting.monthlyBudget.value && _vm.item.budgeting.dailyBudget.value
        }},[(!_vm.item.budgeting.monthlyBudget.value && _vm.item.budgeting.dailyBudget.value)?_c('styled-tooltip',{attrs:{"position":"top","max-width":300},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" This account is spending but a budget goal must be set for the calculations to work ")]},proxy:true}],null,false,1850114495)},[_vm._v(" "+_vm._s(_vm.item.budgeting.dailyBudget.formatted)+" ")]):[_vm._v(" "+_vm._s(_vm.item.budgeting.dailyBudget.formatted)+" ")]],2):(_vm.type === 'averageDailyBudget' && _vm.item.budgeting.averageDailyBudget.value)?_c('div',[_vm._v(" "+_vm._s(_vm.item.budgeting.averageDailyBudget.formatted)+" ")]):(_vm.type === 'suggestedDailyBudget' && _vm.item.budgeting.suggestedDailyBudget.value)?_c('div',[_c('suggested-daily-budget-tooltip',{attrs:{"value":_vm.item.budgeting,"timing":_vm.timing}})],1):(_vm.type === 'suggestedDailyAdjustment' && _vm.item.budgeting.suggestedDailyAdjustment.value)?_c('div',{staticClass:"budget-cell budget-normal",class:[
            _vm.item.budgeting.suggestedDailyAdjustment.value > 1 ? 'budget-alert' : '',
            _vm.item.budgeting.suggestedDailyAdjustment.value < -1 ? 'budget-alert' : '',
            _vm.item.budgeting.suggestedDailyAdjustment.value > 5 ? 'budget-alert-high' : '',
            _vm.item.budgeting.suggestedDailyAdjustment.value < -5 ? 'budget-alert-high' : ''
        ]},[_vm._v(" "+_vm._s(_vm.item.budgeting.suggestedDailyAdjustment.formatted)+" ")]):_c('div',[_vm._v(" -- ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }