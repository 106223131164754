<template>
    <styled-tooltip
        position="top"
        :max-width="350">
        {{ value.suggestedDailyBudget.formatted }}
        <template #content>
            <div class="text-xs-center">
                <h3 class="mt-0 mb-2">
                    <template v-if="value.activeModel.value == 'over_sdb_model'">
                        Over Suggested Daily Budget Model
                    </template>
                    <template v-else>
                        Default Model
                    </template>
                </h3>
            </div>

            <!-- Overpacing model -->
            <div
                v-if="value.activeModel.value == 'over_sdb_model'"
                class="budgeting-equation mb-2">
                <!-- First segment -->
                <div class="budgeting-equation-brace">
                    (
                </div>
                <div>
                    <div>{{ value.monthlyBudget.formatted }}</div>
                    <div>Monthly <br>Budget</div>
                </div>
                <div class="budgeting-equation-symbol">
                    &minus;
                </div>
                <div>
                    <div>{{ value.spendToDate.formatted }}</div>
                    <div>Spend to <br>Date</div>
                </div>
                <div class="budgeting-equation-brace">
                    )
                </div>

                <!-- Divider -->
                <div class="budgeting-equation-symbol">
                    &divide;
                </div>

                <!-- Second segment -->
                <div>
                    <div>{{ timing.daysRemainingInMonth - 1 }}</div>
                    <div>Days Remaining<br>Minus Today</div>
                </div>
            </div>

            <!-- Underpacing model -->
            <div
                v-else
                class="budgeting-equation">
                <!-- First segment -->
                <div class="budgeting-equation-brace">
                    (
                </div>
                <div>
                    <div>{{ value.monthlyBudget.formatted }}</div>
                    <div>Monthly <br>Budget</div>
                </div>
                <div class="budgeting-equation-symbol">
                    &minus;
                </div>
                <div>
                    <div>{{ value.spendToYesterday.formatted }}</div>
                    <div>Spend to <br>Yesterday</div>
                </div>
                <div class="budgeting-equation-brace">
                    )
                </div>

                <!-- Divider -->
                <div class="budgeting-equation-symbol">
                    &divide;
                </div>

                <!-- Second segment -->
                <div>
                    <div>{{ timing.daysRemainingInMonth }}</div>
                    <div>Days <br>Remaining</div>
                </div>
            </div>
        </template>
    </styled-tooltip>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip.vue';

export default {
    components: {
        StyledTooltip,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        timing: {
            type: Object,
            required: true,
        },
    },

};
</script>
