<template>
    <div>
        <div v-if="type === 'spendToDate' && item.budgeting.spendToDate.value">
            ${{ item.budgeting.spendToDate.value }}
        </div>
        <div v-else-if="type === 'projectedSpend' && item.budgeting.projectedSpend.value">
            <projected-spend-tooltip
                :value="item.budgeting"
                :timing="timing" />
        </div>
        <div
            v-else-if="type === 'dailyBudget' && item.budgeting.dailyBudget.value"
            class="budget-cell"
            :class="{
                'budget-warning': !item.budgeting.monthlyBudget.value && item.budgeting.dailyBudget.value
            }">
            <styled-tooltip
                v-if="!item.budgeting.monthlyBudget.value && item.budgeting.dailyBudget.value"
                position="top"
                :max-width="300">
                {{ item.budgeting.dailyBudget.formatted }}
                <template #content>
                    This account is spending but a budget goal must be set for the calculations to work
                </template>
            </styled-tooltip>
            <template v-else>
                {{ item.budgeting.dailyBudget.formatted }}
            </template>
        </div>
        <div v-else-if="type === 'averageDailyBudget' && item.budgeting.averageDailyBudget.value">
            {{ item.budgeting.averageDailyBudget.formatted }}
        </div>
        <div v-else-if="type === 'suggestedDailyBudget' && item.budgeting.suggestedDailyBudget.value">
            <suggested-daily-budget-tooltip
                :value="item.budgeting"
                :timing="timing" />
        </div>
        <div
            v-else-if="type === 'suggestedDailyAdjustment' && item.budgeting.suggestedDailyAdjustment.value"
            class="budget-cell budget-normal"
            :class="[
                item.budgeting.suggestedDailyAdjustment.value > 1 ? 'budget-alert' : '',
                item.budgeting.suggestedDailyAdjustment.value < -1 ? 'budget-alert' : '',
                item.budgeting.suggestedDailyAdjustment.value > 5 ? 'budget-alert-high' : '',
                item.budgeting.suggestedDailyAdjustment.value < -5 ? 'budget-alert-high' : ''
            ]">
            {{ item.budgeting.suggestedDailyAdjustment.formatted }}
        </div>
        <div v-else>
            --
        </div>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import ProjectedSpendTooltip from '@/components/globals/budgeting/ProjectedSpendTooltip';
import SuggestedDailyBudgetTooltip from '@/components/globals/budgeting/SuggestedDailyBudgetTooltip';
import { calculateTiming } from '@/apis/global/budgeting/calculateBudgeting';

export default {
    components: {
        StyledTooltip,
        ProjectedSpendTooltip,
        SuggestedDailyBudgetTooltip,
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    created() {
        this.timing = calculateTiming();
    }
};
</script>

<style lang="scss" scoped>
.budget-cell {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.budget-normal {
    background-color: rgba($gray, 0.1);
}
.budget-warning {
    background-color: rgba($yellow-dark, 0.8);
}
.budget-alert {
    background-color: lighten($carnation, 20%);
}
.budget-alert-high {
    background-color: $carnation;
}
</style>
