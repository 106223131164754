<template>
    <styled-tooltip
        position="top"
        :max-width="500">
        {{ value.projectedSpend.formatted }}
        <template #content>
            <div class="budgeting-equation">
                <div>
                    <div>{{ value.spendToYesterday.formatted }}</div>
                    <div>Spend to <br>Yesterday</div>
                </div>
                <div class="budgeting-equation-symbol">
                    +
                </div>
                <div class="budgeting-equation-brace">
                    (
                </div>
                <div>
                    <div>{{ value.dailyBudget.formatted }}</div>
                    <div>Daily <br>Budget</div>
                </div>
                <div class="budgeting-equation-symbol">
                    &times;
                </div>
                <div>
                    <div>{{ timing.daysRemainingInMonth }}</div>
                    <div>Days <br>Remaining</div>
                </div>
                <div class="budgeting-equation-brace">
                    )
                </div>
            </div>
        </template>
    </styled-tooltip>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip.vue';

export default {
    components: {
        StyledTooltip,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        timing: {
            type: Object,
            required: true,
        },
    },

};
</script>
